import { render, staticRenderFns } from "./unit-card.vue?vue&type=template&id=e831edd6&scoped=true"
import script from "./unit-card.vue?vue&type=script&lang=js"
export * from "./unit-card.vue?vue&type=script&lang=js"
import style0 from "./unit-card.vue?vue&type=style&index=0&id=e831edd6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e831edd6",
  null
  
)

export default component.exports